import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setReduxCurrentPlan } from "../redux/dashboardSlice";
import { getDate } from "../utils/getData";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";

const CurrentPlan = (props: any) => {
  const { subscribe, info } = props;

  const [activePlan, setActivePlan] = useState({
    plan: {
      name: "",
      price: "",
    },
    subscription: {
      current_period_ends_at: "",
      end_period_last_days: "",
      status: ""
    },
  });

  const token = getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/customers/subscriptions`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.request(config);
        const data = response?.data;
        
        console.log(data);
        dispatch(setReduxCurrentPlan(data));
        setActivePlan(data?.data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchSubscription();
  }, [dispatch, token]);

  return (
    <div className="bg-white p-5 basis-[50%] relative rounded-xl shadow">
      <span className="text-[var(--primary-color)] text-[18px] font-extrabold pb-[10px]">
        Current Plan
      </span>
      <div className="text-[#C7C7C7] text-[16px] font-normal mt-2">
        You are currently subscribed to the
        <span className="mx-2 text-orange-500">{activePlan?.plan?.name  || "Plan Name"}</span>
        plan
        <span className="mx-2 text-orange-500">
          Rs {activePlan?.plan?.price || 0}
        </span>, your subscription will expire in
        <span className="mx-2 text-orange-500">
          {activePlan?.subscription?.end_period_last_days  || 0}
        </span> days
        <span className="mx-2 text-orange-500">
          {getDate(activePlan?.subscription?.current_period_ends_at  || "30")}
        </span>
      </div>
    </div>
  );
};

export default CurrentPlan;
