import React, { useEffect, useState } from "react";
import { BiGroup, BiListPlus, BiPlus } from "react-icons/bi";
import { BsFillPeopleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiBaseUrl } from "../../../globals/baseUrl";
import { getToken } from "../../../utils/tokenUtils";
import { dropUpDownArrow } from "../../dropUpDownarrow";
 
const Customers = (props: any) => {
  const [dropDown, setDropDown] = useState(false);
  const [isReseller, setIsReseller] = useState(true); 
  const token = getToken();

  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  useEffect(() => {
    const fetchResellerStatus = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/users/is-reseller`, {
          headers: {
            'Content-type': 'application/json',
            "Authorization": `Bearer ${token}` 
          }
        });
        console.log("is_reseller",response);
        setIsReseller(response.data.is_reseller); // Update state with the response
      } catch (error) {
        console.error("Error fetching reseller status:", error);
        setIsReseller(false); // Fallback or handle error appropriately
      }
    };

    fetchResellerStatus();
  }, [token]);

  // Return null or nothing if the user is not a reseller
  if (!isReseller) {
    return <></>;
  }

  return (
    <div className="flex items-center flex-col gap-[8px] rounded-[6px] w-[223px] cursor-pointer">
      <div
        className={
          active === "customers"
            ? "nav_active flex items-center gap-[16px] pl-[18px] py-[12px] nav_link w-full rounded-[6px] group"
            : "flex items-center gap-[16px] py-[12px] pl-[18px] nav_link w-full rounded-[6px] group"
        }
        onClick={() => {
          setDropDown(!dropDown);
        }}
      >
        <div className="flex items-center gap-[16px]">
          <BsFillPeopleFill />
          <p
            className={
              active === "customers"
                ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px]"
                : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px]"
            }
          >
            Customers
          </p>
        </div>
        {dropUpDownArrow(dropDown)}
      </div>

      {dropDown && (
        <div className="w-full bg-[#006AAD1A] transition duration-300 ease-out flex flex-col gap-[8px]">          

          <NavLink to="/customers">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link rounded-[6px]">
              <BiGroup />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px]">
                Customers
              </p>
            </div>
          </NavLink>
          <NavLink to="/customers/add-customer">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link rounded-[6px]">
              <BiPlus />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px]">
                Add Customers
              </p>
            </div>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Customers;
