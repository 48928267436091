import axios from "axios";

export const apiBaseUrl = "https://smsvendor.com/public/api/v3";
export const imageBaseUrl = "https://smsvendor.com/public/avatar";
let token = localStorage.getItem("token");
 
export const api = axios.create({
  baseURL: "https://smsvendor.com/public/api/v3",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
 
// export const apiBaseUrl = "http://localhost:8000/api/v3";
// export const imageBaseUrl = "http://localhost:8000/avatar";
// let token = localStorage.getItem("token");
 
// export const api = axios.create({
//   baseURL: "http://localhost:8000/api/v3",
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });
 