import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';

import { Select } from 'antd';
import { showFailedToastAlert, showSuccessfulToastAlert } from '../toastAlert.js/toastalert';
import { apiBaseUrl } from '../globals/baseUrl';
import axios from 'axios';
import { getToken } from '../utils/tokenUtils';
import { useNavigate, useParams } from 'react-router-dom';
import RegistrationPopup from './RegistrationPopup'
import { useSelector } from 'react-redux';
 
// Define interfaces for typess
interface Plan {
  id: number;
  name: string;
  price: number | string;
}

interface PaymentMethod {
  id: number;
  name: string;
}

interface Language {
  code: string;
  name: string;
}

interface FormState {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  phone: string;
  timezone: string;  
  address: string;
  city: string;
  country: string;
  plans: number | '';
  locale: string;
  payment_methods: number | '' | string;
  post_code: string;
  company: string;
  uid: string;
  amount: number;
  status?: string;
}

interface Errors {
  password: string;
  password_confirmation: string;
}


const NewCustomer: React.FC = () => {
  const token = getToken();
  const user = useSelector((state :any)=>state.reducers.userSlice.user);
 

  let formInitialState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    timezone: 'Asia/Kathmandu',
    address: 'kathmandu',
    city: 'kathmandu',
    country: 'nepal',    
    plans: 1,    
    is_reseller: 0,
    locale: 'en',
    payment_methods: '',
    post_code: '44600',
    company: '',
    uid: user?.uid,
    amount: 0,
  }

 

  const [form, setForm] = useState<FormState>(formInitialState);

  const [customerPlans, setCustomerPlans] = useState<Plan[]>([]);
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState<PaymentMethod[]>([]);
 
  const [errors, setErrors] = useState<Errors>({ password: '', password_confirmation: '' });
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    url: 'https://login.easy.com.np'
  })



  const handleClosePopup = () => {
    setShowPopup(false);
  };

  
  useEffect(() => {
    const getPlans = async () => {
      try {
        const modifiedBaseUrl = apiBaseUrl.replace('/v3', '');
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${modifiedBaseUrl}/http/getPlans`,
        };
  
        const response = await axios.request(config);  
        console.log('data', response.data.data.plans)
        setCustomerPlans(response.data.data.plans || []); 
        setCustomerPaymentMethod(response.data.data.payment_methods || []); 
  
      } catch (error: any) {
        console.error('Error fetching plans:', error.response?.data || error.message);
      }
    };
  
    getPlans();
  }, []);
  
  useEffect(() => {
    console.log('Payment Methods State Updated:', customerPaymentMethod);
  }, [customerPaymentMethod]);
  
  

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeSelectBox = (e: ChangeEvent<HTMLSelectElement>, name: keyof FormState) => {
    setForm((prevState) => ({ ...prevState, [name]: e.target.value }));
  };
  



  const validateForm = (): boolean => {
    let isValid = true;
    if (form.password.length < 8) {
      setErrors((prev) => ({ ...prev, password: 'Password must be at least 8 characters' }));
      isValid = false;
    } else {
      setErrors((prev) => ({ ...prev, password: '' }));
    }

    if (form.password !== form.password_confirmation) {
      setErrors((prev) => ({ ...prev, password_confirmation: "Passwords don't match" }));
      isValid = false;
    } else {
      setErrors((prev) => ({ ...prev, password_confirmation: '' }));
    }

    return isValid;
  };

  let [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e, form) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    if (!validateForm()) {
      showFailedToastAlert('Validation errors. Please check your input.');
      return;
    }

    console.log('form submitting')
    form.uid = user?.uid;
    console.log(form)
    
    setLoading(true); // Start loading spinner or disable submit button
    try {
      console.log(form)
      const config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/auth/reseller`,
        headers: {          
          "Content-Type": "application/json"
        },
        data: form, // Pass the form data
      };
  
      const response = await axios.request(config);
  
      // Check if the response status is successful
      if (response?.status === 201 || response?.status === 200) {
        if(response?.data?.status !== 'success'){
          showFailedToastAlert(response?.data?.message);  
          return
        }

        setUserData({
          username: form?.email,
          password: form?.password,
          url: 'https://login.easy.com.np'
        })

        setShowPopup(true);
        setForm(formInitialState);        
        showSuccessfulToastAlert(response?.data?.message);

      } else {
        showFailedToastAlert('Unexpected response from the server.');
      }
    } catch (error: any) {
      console.log("Error Registering User",error)

      // Log and handle different types of errors

      if (error.response) {
        // Server responded with a status outside of the 2xx range
        console.error('Server Error:', error.response.data);
        showFailedToastAlert(error.response.data.message || 'Server error occurred.');
      } else if (error.request) {
        // Request was made but no response was received
        console.error('No Response:', error.request);
        showFailedToastAlert('No response from server. Please try again later.');
      } else {
        // Something else went wrong
        console.error('Error:', error.message);
        showFailedToastAlert('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false); // Stop loading spinner or re-enable submit button
    }
  };
  

  const getTimeZone = (timezone: string) => {
    setForm((prevForm) => ({ ...prevForm, timezone }));
  };

  return (
    <div className='bg-white mb-10 rounded-[3px]'>
            <div className='mt-[17px] pb-[23px] border-b-[1px]  border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[26px] px-4'>Add New Customers</h2>
            </div>
            <form action="" className='pt-[24px] p-6  w-[100%] grid md:grid-cols-2 gap-3' onSubmit={(e)=>{handleFormSubmit(e,form)}}>
            <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>First Name *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="first_name" value={form.first_name} required/>
                </div>

                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Last Name </h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="last_name" value={form.last_name}/>
                </div>                 
                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Company</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' placeholder='' onChange={handleChange} name="company" type='text' value={form.company} required/>
                </div>
                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Email *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' placeholder='' onChange={handleChange} name="email" type='email' value={form.email} required/>
                </div>

                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Password *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="password" type="password" value={form.password} required/>
                    {errors?.password && (
            <span className="text-[red] text-sm">{errors?.password}</span>
          )}
                </div>

                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Confirm Password *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="password_confirmation"  type="password" value={form.password_confirmation} required/>
                    {errors?.password_confirmation && (
            <span className="text-[red] text-sm">{errors?.password_confirmation}</span>
          )}
                </div>

                  

                  <div className=''>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Phone *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]'  placeholder='' onChange={handleChange} name="phone" value={form.phone} required/>
                </div>

               
              



                <div className='  '>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Status *</h3>
                       <select
                        className='w-full px-[14px] mt-[7px] border-[1px] h-[40px]'
                        
                        onChange={(e)=>onChangeSelectBox(e,'status')}
                 
                    >
                              {[['Active','1'],["Inactive",'0']].map((val, index)=>{
                        return(
                            <option key={index} value={val[1]}>{val[0]}</option>
                        )
                       })}
                    </select>
                </div>

              
               {
                customerPlans && (
                  <div className=" mt-[15px] col-span-2">
                    <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">Plans *</h3>
                    <select
                      className="w-full mt-[9px] border-[1px] px-[14px] h-[40px]"
                      onChange={(e) => {
                        onChangeSelectBox(e, "plans");
                        const selectedPlan: Plan | undefined = customerPlans.find((item) => item.id.toString() === e.target.value);
                        if (!selectedPlan) {
                        console.error("Plan not found");
                        return;
                        }
                        const planPrice = parseInt(selectedPlan.price as string, 10) * 100;

                      }}
                      value={form.plans} // Bind to the form state
                    >
                      <option value="" >Select a plan</option>
                      {customerPlans?.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )
              }
              
                
              {
                customerPaymentMethod && (
                <div className="mt-[15px]  col-span-2">
                  <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">Payment Method *</h3>
                  <select
                    className="w-full mt-[9px] border-[1px] h-[40px] px-[14px]"
                    onChange={(e) => onChangeSelectBox(e, "payment_methods")}
                    value={form.payment_methods} // Bind to the form state
                  >
                    <option value="" disabled>Select a payment method</option>
                      { customerPaymentMethod?.map((method) => (
                      <option key={method?.id} value={method?.id}>
                        {method?.name}
                      </option>
                    ))}
                  </select>
                </div>
                )
              }

            <div className="">
              <button 
                type="submit" 
                disabled={loading} 
                className={`bg-[#0092D7] text-white px-12 py-2  flex items-center justify-center gap-[8px] mt-[15px] rounded-[3px] ${
                  loading ? "cursor-not-allowed bg-gray-400" : "cursor-pointer hover:bg-[#007AB3] active:bg-[#005F8A]"
                } transition-all`}
              >
               
                <p className="text-[16px] font-normal">{loading ? "Creating User..." : "Create"}</p>
              </button>
            </div>

            </form>

            {showPopup && (
        <RegistrationPopup
          userId={userData.username}
          password={userData.password}
          url={userData.url}
          onClose={handleClosePopup}
        />
      )}
        </div>
  );
};

export default NewCustomer;
