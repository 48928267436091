import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import axios from "axios";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import ExcelPreview from "./ExcelPreview";
import "../ExcelPreview.css";
import PreviewMessage from "./PreviewMessage";
import { checkUnicode } from "../utils/checkUnicode";
import TextArea from "antd/es/input/TextArea";
import { Oval } from "react-loader-spinner";

const QuickSender = () => {
  const [formData, setFormData] = useState<any>();
  const [reciepient, setReciepient] = useState("");
  const [language, setLanguage] = useState("en");
  const [message, setMessage] = useState("");
  const [quickSelectFile, setQuickSelectFile] = useState(null);

  const token = getToken();
  const [allData, setAllData] = useState<any>(null);
  const [selectedSendingServer, setSelectedSendingServer] = useState<any>(null);
  const [selectedSenderId, setSelectedSenderId] = useState<any>(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isHeader, setIsHeader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");

  console.log(selectedSenderId, selectedSendingServer);

  // console.log(allData);
  useEffect(() => {
    // -------------------getting form data ---------------
    axios
      .get(`${baseUrl}/customers/sms/quick-send`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          recipient: "required",
        },
      })
      .then((response) => {
        console.log(response?.data);
        const data = response?.data;
        console.log("ALL Data",data);
        setFormData(data);
        if (data.status === "success") {
          // -------- Updating All Data ----------
          console.log("Data: ",data.data);
          setAllData(data.data);
          // ------- Updating SenderId ---------------
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // -------------Form data end -----------

  useEffect(() => {
    if (allData !== null) {
      setSelectedSenderId(allData?.sender_ids[0]?.sender_id);
      setSelectedSendingServer(allData?.sending_server[0]?.id);
    }
  }, [allData]);

  // -------------SENDING SMS -------------------------
  const sendSms = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        _token: "djAZY0TazNmjP2j9ONjUz2YN8DoCErEKq9pKk6b4",
        recipients: reciepient === "" ? "null" : reciepient,
        originator: "sender_id",
        sender_id: selectedSenderId,
        message: message,
        sms_file: quickSelectFile,
        delimiter: ",",
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/sms/quick-send`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          const data = response?.data;
          if (data?.status === "success") {
            showSuccessfulToastAlert(data?.message);
            window.location.reload();
          } else {
            showFailedToastAlert(data?.message);
          }

          // navigate("./reports/sent")
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Unable to send message. Please Check your Credentials!");
          showFailedToastAlert(
            "Unable to send message. Please Check your Credentials!"
          );
        });
    } catch (error: any) {
      setLoading(false);
      showFailedToastAlert(
        "Unable to send message. Please Check your Credentials!"
      );
    }
    // -----------------sending sms end ----------------
  };

  // starting code for message credit and showing remaining letter
  let messageCount = 160;
  const max = language === "en" ? 160 : 70;
  let messageRemaining =
    language === "en"
      ? max - (message.length % 160)
      : max - (message.length % 70);
  // ending code for message credit and showing remaining letter

  const handleMessageChange = (event: any) => {
    if (checkUnicode(event?.target?.value)) {
      setLanguage("nep");
    }
    setMessage(event?.target?.value);
    messageCount = message.length;
  };

  const [selectedTab, setSelectedTab] = useState("tab1");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSendEom = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Template Message", templateMessage);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("marks_file", selectedFile);

      axios
        .post(
          `${baseUrl}/customers/sms/send-marks-campaign?header=${
            isHeader ? 1 : 0
          }&template_message=${encodeURIComponent(templateMessage)}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          showSuccessfulToastAlert(response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          // Handle API error here
          setLoading(false);
          console.error(error);
          showFailedToastAlert(error.message);
        });
    } else {
      setLoading(false);
      showFailedToastAlert("Please select a file before sending.");
    }
  };

  const handleEomHeaderChange = (event) => {
    const selectedValue = event.target.value === "first";
    setIsHeader(selectedValue);
  };

  return (
    <div className="h-[80vh] px-3 md:px-0">
      <div className="flex mt-5 text-[15px]">
        <button
          onClick={() => setSelectedTab("tab1")}
          className={`[p-1 pl-3 pr-4  mb-2 ${
            selectedTab === "tab1" ? "bg-[#0088ce]" : "bg-white"
          } ${
            selectedTab === "tab1" ? "text-[#fff]" : "text-[#000]"
          }  rounded-[5px] shadow`}
        >
          Normal SMS
        </button>
        <button
          onClick={() => setSelectedTab("tab2")}
          className={`p-1 pl-3 pr-4 ml-4 mb-2  ${
            selectedTab === "tab2" ? "bg-[#0088ce]" : "bg-white"
          } ${
            selectedTab === "tab2" ? "text-[#fff]" : "text-[#000]"
          } rounded-[5px] shadow`}
        >
          EOM SMS
        </button>
      </div>

      {selectedTab === "tab1" && (
        <div className="bg-white mt-[20px] rounded-[5px] pb-[38px]  shadow p-5 ">
          <div className="border-b-[1px] border-[#D9D9D9]">
            <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] py-4">
              Quick SMS Sender
            </h2>
          </div>
          <form
            // action=""
            id="sms_quick_sender"
            className=" pl-[12px] large_mobile:pl-[18px] md:pl-[28px] flex flex-col "
            onSubmit={sendSms}
          >
            <div className="grid gap-y-4 md:flex gap-x-4 mt-4">
              <div className=" w-full">
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                  Sending Server *
                </h2>
                <select
                  className="mt-[8px] text-[13px] h-[32px] w-full border-[1px] border-[#D9D9D9] bg-white"
                  onChange={(e) => setSelectedSendingServer(e.target.value)}
                  value={selectedSendingServer}
                >
                  {allData?.sending_server?.map((val, index) => (
                    <option
                      selected={index === 0 ? true : false}
                      value={val.id}
                    >
                      {val.settings}
                    </option>
                  ))}
                </select>
              </div>
              {/* ------------ sending server end ----------- */}

              {/* ------------ sender id ------------------- */}
              <div className=" w-full">
                <div className=" gap-[24px]">
                  <div className="">
                    <div className="flex items-center gap-[8px]">
                      <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                        Sender ID
                      </p>
                    </div>

                    {allData ? (
                      <select
                        className="mt-[8px] text-[13px] h-[32px] w-full border-[1px] border-[#D9D9D9] bg-white"
                        onChange={(e) => setSelectedSenderId(e.target.value)}
                        value={selectedSenderId}
                      >
                        {allData?.sender_ids?.map((val, index) => (
                          <option
                            selected={index === 0 ? true : false}
                            value={val.sender_id}
                          >
                            {val.sender_id}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={selectedSenderId}
                        required
                        className="mt-[8px] h-[35px] w-[100%] border-[1px] bg-white border-gray-300 outline-none pl-3 text-[13px]"
                        onChange={(e) => setSelectedSenderId(e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* file */}

              <div className=" w-full">
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
                  Select File
                </h2>
                <div className="flex items-center">
                  <input
                    type="file"
                    name="sms_file"
                    onChange={async (e: any) => {
                      const file = e.target.files[0];
                      setQuickSelectFile(file);
                    }}
                    className="mt-3  text-[13px] "
                    accept=".xlsx,.xls,.csv,.txt"
                  />
                </div>
              </div>
            </div>
            {/* --------------Sending Server ------------ */}

            {/* ------------ reciepient end ------------------- */}

            {/* File Send */}
            <div className="flex items-center max-sm:flex-col">
              <div className="mt-4 w-full">
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
                  Recipient
                </h2>
                <div className="flex items-center">
                  {/* <div className="w-[100px]">
                    <Select
                      defaultValue={"+977"}
                      style={{ width: "100%" }}
                      // onChange={handleCountryCodeChange}
                      className="mt-[8px] w-[100px]"
                      options={[{ value: "+977" }]}
                      aria-required
                    />
                  </div> */}

                  <textarea
                    // onChange={handleChange}
                    name="recipient"
                    onChange={(e) => {
                      e.preventDefault();
                      setReciepient(e.target.value);
                    }}
                    placeholder="Seperate numbers by ' , ' example: 9812345678,9852325214"
                    className="border border-[#D9D9D9] pl-[14px] outline-none bg-white h-[38px] flex-grow mt-[8px] text-[13px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                  />
                </div>
              </div>
            </div>

            {/* ------------- Sender id --------------------*/}

            {/* ----------------message ------------------- */}
            {/* <div className=" mt-[24px] pr-[24px]">
              <div className="flex items-center gap-[8px]">
                <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                  Choose Language
                </p>
              </div>

              <select
                value={language}
                style={{ width: "100%" }}
                onChange={(e) => setLanguage(e.target.value)}
                className="mt-[8px] text-[13px] h-[32px] border-[1px] border-[#D9D9D9] bg-white"
              >
                <option className="bg-white " value="en">
                  Plain
                </option>
                <option className="bg-white " value="nl">
                  Unicode
                </option>
              </select>
            </div> */}

            <div className="mt-[20px] pr-[24px]">
              <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Message *
              </h2>
              <textarea
                // lang={`${language === "en" ? "en" : "ne"}`}
                // style={{
                //   fontFamily: `${
                //     language === "en" ? "Arial Helvetica sans-serif" : "Preeti"
                //   }`,
                // }}
                onChange={handleMessageChange}
                name="message"
                id="w3review"
                className="border w-full h-[136px] mt-[4px] bg-white rounded-[1px] pt-[12px] pl-[14px] outline-none text-[15px]"
                placeholder="message"
                required
              ></textarea>
            </div>

            <div className="flex justify-between ">
              <span>Remaining:{messageRemaining}</span>
              <span>
                Credit:{" "}
                {language === "en"
                  ? message.length >= 160
                    ? Math.ceil(message.length / 153)
                    : Math.ceil(message.length / 160)
                  : message.length >= 70
                  ? Math.ceil(message.length / 67)
                  : Math.ceil(message.length / 70)}
              </span>
            </div>

            {/*------------------ message end --------------- */}
            <button
              type="submit"
              id="sms_quick_sender"
              className="flex justify-end mr-[25px]"
              disabled={loading}
            >
              <div className="w-[120px] h-[38px] flex items-center justify-center text-white rounded-[2px] mt-[13px] cursor-pointer bg-[#0092D7] self-end">
                <p className="text-[14px] leading-[16.41px] font-normal">
                  Send
                </p>
              </div>
            </button>
          </form>
        </div>
      )}

      {selectedTab === "tab2" && (
        <div className="bg-white mt-[20px] max-w-[900px] rounded-[5px] pb-[38px shadow-lg shadow-black p-5">
          <div className="border-b-[1px] border-[#D9D9D9]">
            <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
              Upload Message
            </h2>
          </div>
          <div className="bg-white p-6 rounded max-w-sm mx-auto">
            <a
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              href="/sms_sample.xlsx"
              download
            >
              Download Sample File
            </a>
          </div>
          <form
            // action=""
            id="sms_quick_sender"
            className="pl-[28px] flex flex-col "
          >
            {/* Radio button */}
            <div className="flex flex-wrap gap-4 align-middle mt-[5px] w-full justify-around">
              <div>
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-3">
                  EOM TYPE *
                </h2>
                <label className="mr-3">
                  <input
                    checked={isHeader}
                    value="first"
                    type="radio"
                    name="eomType"
                    onChange={handleEomHeaderChange}
                  />
                  &nbsp; Header
                </label>

                <label>
                  <input
                    checked={!isHeader}
                    value="second"
                    type="radio"
                    name="eomType"
                    onChange={handleEomHeaderChange}
                  />
                  &nbsp; Non Header
                </label>
              </div>

              <div>
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-3">
                  Upload (.xlsx) *
                </h2>
                <input
                  className="bg-white"
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileChange}
                />
              </div>
              <button
                onClick={handleSendEom}
                disabled={loading}
                className="bg-green-500 text-white font-bold py-2 px-4 rounded"
              >
                Send
              </button>
            </div>
            <div className="mx-10 mt-2">
              {isHeader && (
                <div>
                  <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-3">
                    Template Message
                  </h2>
                  <label className="mr-3">
                    <TextArea
                      placeholder="Enter Template Message"
                      name="eomType"
                      value={templateMessage}
                      onChange={(e) => {
                        setTemplateMessage(e.target.value);
                      }}
                    />
                  </label>
                </div>
              )}
            </div>

            <div className="flex justify-between">
              <ExcelPreview file={selectedFile} isHeader={isHeader} />
            </div>
            {/* ------------ sending server end ----------- */}

            {/* ------------ reciepient ------------------- */}
            <div className="mt-[24px] pr-[24px]">
              <div className="flex items-center mb-3">
                <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
                  What is EOM ?
                </h2>
                <hr className="flex-1 ml-4 h-[1.5px] bg-black" />
              </div>
              <div className="flex items-center text-[13px]">
                Eom is event oriented message , which allows the users to send
                unique messages to each designated recivers . if the users has
                significant amount of unique message respect to receivers then
                it reduces the burden of sending individual message each time
                seperately.
                <br />
                If you want to communicate with a large number of people at once
                but want to give specific information to specific people then
                EOM can help you. Individual get what is relevent to them. No
                need to mannually send single SMS to single number.
              </div>
            </div>

            <div className="mt-[24px] pr-[24px]">
              <div className="flex items-center">
                <h2 className=" text-[#545454] text-[13px] leading-[15.23px] font-normal ">
                  How to send EOM Message ?
                </h2>
                <hr className="flex-1 ml-4 h-[2px] bg-black" />
              </div>
              <div className="mt-4 text-[13px]">
                EOM provides you two option for uploading messages .Select as
                per requirment.
              </div>
            </div>

            <div className="mt-4 text-[13px]">
              <span className="text-red-600 ">1 With Header :</span>
              This allows you to send seperate messages with heading For example
              : From right side image, EOM will produce following Messages and
              sends to designated numbers in cell Number column or last column
            </div>
            <hr className="mt-3 w-full h-[1px] bg-black" />

            <div className="mt-3 text-[13px]">
              <span className="text-red-600">2 With-out Header :</span>
              This allows you to send seperate messages without heading. <br />
              For example : From right side image, EOM will produce following
              Messages and sends to designated numbers in last column
              <br />
              From top image
              <br />
              <span className="text-blue-500">
                Membership expire on 8th Dec 2016
              </span>
              <br />
              From bottom image
              <br />
              <span className="text-blue-500">Anil khadka</span>
              <br />
              <span className="text-blue-500">
                Membership expire on 8th Dec 2016
              </span>
            </div>
          </form>
        </div>
      )}

      {loading ? (
          <div className="fullWindow-Spinner">
            <div>
              <Oval visible={loading}></Oval>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting...
            </div>
          </div>
        ) : null}
    </div>
  );
};

export default QuickSender;
