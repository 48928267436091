import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../globals/baseUrl';
import { getToken } from '../utils/tokenUtils'; 


const ResellerRoute = ({ children }: { children: React.ReactNode }) => {
  const [isReseller, setIsReseller] = useState(true); 
  const token = getToken();

 

  useEffect(() => {
    const fetchResellerStatus = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/users/is-reseller`, {
          headers: {
            'Content-type': 'application/json',
            "Authorization": `Bearer ${token}`,
          },
        });
        console.log("is_reseller", response);
        setIsReseller(response.data.is_reseller); // Update state with the response
      } catch (error) {
        console.error("Error fetching reseller status:", error);
        setIsReseller(false); // Fallback or handle error appropriately
      }
    };

    fetchResellerStatus();
  }, [token]);

  // Redirect if the user is not a reseller
  if (!isReseller) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default ResellerRoute;
