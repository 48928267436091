import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import usePostData from "../hooks/usePostData";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";

interface Plan {
  id: number | string;
  uid: string;
  billing_cycle: string;
  price: number;
  currency_id: number;
}

const SenderIdreq = () => {
  const token = getToken();
  const [requestedSenderId, setRequestedSenderId] = useState("");
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.reducers.userSlice.user);

  useEffect(() => {
    const fetchPlans = async () => {
      const config = {
        url: `${baseUrl}/customers/senderid/getPlans`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.request(config);
        console.log("Plan Response", response);
        setPlans(response.data.plans);
      } catch (error) {
        console.error("Error fetching plans", error);
      }
    };

    fetchPlans();
  }, [token]);

  const handlePlanChange = (planId: string) => {
    const plan = plans.find((item) => item.id.toString() === planId);
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    const Data = JSON.stringify({
      sender_id: requestedSenderId,
      plan: Number(selectedPlan?.id),
      status: "active",
      price: selectedPlan?.price || 0,
      billing_cycle: selectedPlan?.billing_cycle || "monthly",
      user_uid: user?.uid,
      currency_id: selectedPlan?.currency_id || 2,
      frequency_unit: "day",
    });

    console.log(Data);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/senderid`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: Data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        const data: any = response.data;
        if (data.status === 200) {
          showSuccessfulToastAlert(data.message);
          navigate("/senderid");
        } else {
          showFailedToastAlert(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailedToastAlert(error);
      });
  };

  return (
    <div className=" h-[70vh]">
      <div className="bg-white mt-[20px] max-w-[513px] rounded-[5px] pb-[30px]">
        <ToastContainer />
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            Request for new one
          </h2>
        </div>
        <form action="" className="pl-[31px]" onSubmit={(e) => handleSubmit(e)}>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sender ID *
            </h2>
            <input
              type="text"
              name="sender_id"
              value={requestedSenderId}
              onChange={(e) => setRequestedSenderId(e.target.value)}
              placeholder="Required"
              className="border border-gray-300  rounded bg-[#fff] border-r-8 pl-[14px] outline-none  h-[38px] w-[452px] mt-2 placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
            />
          </div>
          <div className="mt-[24px] w-[452px] overflow-hidden">
            <h2 className="text-[#545454]  text-[13px] leading-[15.23px] font-normal ">
              Select Plan *
            </h2>
            <select
              name="plan"
              style={{
                width: "100%",
                height: "38px",
              }}
              onChange={(e: any) => handlePlanChange(e.target.value)}
              className="border border-gray-300  rounded bg-[#fff] border-r-8 pl-[14px] outline-none  h-[38px] w-[452px] mt-2 placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
            >
              <option value="">Select Plan</option>
              {plans.map((item: Plan) => (
                <option
                  value={item.id}
                  key={item.uid}
                  className="w-full absolute py-4 h-[38px] text-[14px]"
                >
                  {item.billing_cycle}
                </option>
              ))}
            </select>
          </div>

          {selectedPlan && (
            <div className="mt-[24px]">
              <h2 className="text-[#545454] text-2xl leading-[15.23px] font-normal ">
                Selected Plan Details
              </h2>
              <p>Billing Cycle: {selectedPlan.billing_cycle}</p>
              <p>Price: {selectedPlan.price}</p>
              <p>Currency ID: {selectedPlan.currency_id}</p>
            </div>
          )}

          <div className="w-[100%] h-[34px] flex items-center justify-end text-white rounded-[2px] mt-[40px] cursor-pointer">
            <button
              type="submit"
              className="w-[100px] mr-4 bg-[#0092D7] h-full"
            >
              Save
            </button>
          </div>
        </form>
        {loading && (
          <div className="fullWindow-Spinner">
            <div>
              <Oval visible={loading}></Oval>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting...
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SenderIdreq;
