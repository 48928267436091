import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import AddNewBlacklist from "../components/AddNewBlacklist";
import AddnewTemplate from "../components/SideNavigation/Templates/AddnewTemplate";
import Blacklist from "../components/Blacklist";
import CampaignBuilder from "../components/CampaignBuilder";
import ViewContact from "../components/ContactGroups/ViewContact";
import Chatbox from "../components/Chatbox";
import ContactGroup from "../components/ContactGroup";
import Import from "../components/Import";
import Inbox from "../components/Inbox";
import NewContactGroup from "../components/NewContactGroup";
import Numbers from "../components/Numbers";
import Profile from "../components/Profile";
import QuickSender from "../components/QuickSender";
import SenderId from "../components/SenderId";
import SenderIdreq from "../components/SenderIdreq";
import Sendflie from "../components/Sendflie";
import Sent from "../components/Sent";
import Smstemplate from "../components/Smstemplate";
import DevDocuments from "../docs";
import Dashboard from "../pages/Dashboard";
import Developers from "../pages/Developers";
import Login from "../pages/Login";
import Billing from "../pages/Billing";
import VoiceCampaignBuilder from "../pages/Voice/VoiceCampaignBuilder";
import VoiceQuickSend from "../pages/Voice/VoiceQuickSend";
import VoiceSendFile from "../pages/Voice/VoiceSendFile";
import MMSCampaignBuilder from "../pages/MMS/MMSCampaignBuilder";
import MMSQuickSender from "../pages/MMS/MMSQuickSender";
import MMSFileSender from "../pages/MMS/MMSFileSender";
import SpecificNumberQuickSend from "../components/ContactGroups/specificContactQuickSend";
import WhatsAppCampaignBuilder from "../components/Whatsapp/WhatsAppCampaignbuilder";
import WhatsAppFileSender from "../components/Whatsapp/WhatsAppFileSender";
import WhatsAppQuickSender from "../components/Whatsapp/WhatsAppQuickSender";
import TopUp from "../components/TopUp";
import VoiceQuickSender from "../pages/Voice/VoiceQuickSend";
import MainDashBoardComponent from "../components/Dashboard/MainDashBoardComponent";
import { getToken } from "../utils/tokenUtils";
import { useEffect } from "react";
import UpdateTemplate from "../components/UpdateTemplate";
import SendMessage from "../components/Reports/SendMessage";
import ReceivedMessage from "../components/Reports/ReceivedMessage";
import Allmessage from "../components/Reports/Allmessage";
import Campaigns from "../components/Reports/Campaigns";
import AddPhoneNumber from "../components/ContactGroups/AddPhoneNumber";
import UpdateContact from "../components/ContactGroups/UpdateContact";
import EomRecords from "../components/EomRecords";
import EomRecordData from "../components/EomRecords/show";
import OtpInputPage from "../components/OTP/Pages/OtpInputPage";
import Transactions from "../components/BillingComponents/subscriptionComponents/Transactions";
import UpdateBlacklist from "../components/UpdateBlacklist";
import Register from "../components/Register";
import PaymentSuccessAndRegister from "../pages/PaymentSuccessAndRegister";
import NewCustomer from "../components/NewCustomer";
import Customers from "../components/Customers";
import ResellerRoute from "./ResellerRoute";
const Router = () => {
  const token: any = getToken();

  return (
    <div> 
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<PaymentSuccessAndRegister></PaymentSuccessAndRegister>} />
          {/* otp  */}
          <Route path="/otp-input" element={<OtpInputPage />} />
          <Route path="/" element={token ? <Dashboard /> : <Login />}>
            <Route path="dashboard" element={<MainDashBoardComponent />} />
            <Route path="reports/allmessage" element={<Allmessage />} />
            <Route path="reports/received" element={<ReceivedMessage />} />
            <Route path="reports/sent" element={<SendMessage />} /> 
            <Route path="/reports/campaign" element={<Campaigns />} />
            <Route path="senderid" element={<SenderId />} />
            <Route path="senderid/request" element={<SenderIdreq />} />

 
            <Route path="/customers/add-customer" element={<ResellerRoute><NewCustomer /></ResellerRoute>} />
            <Route path="/customers" element={<ResellerRoute><Customers /></ResellerRoute>} />

            <Route path="numbers" element={<Numbers />} />
            <Route path="sms-template" element={<Smstemplate />} />
            <Route path="sms/campaign-builder" element={<CampaignBuilder />} />
            <Route path="sms/quick-send" element={<QuickSender />} />

            <Route path="docs" element={<DevDocuments />} />
            <Route path="voice/campaign" element={<VoiceCampaignBuilder />} />
            <Route path="voice/quick-send" element={<VoiceQuickSender />} />
            <Route path="voice/send-file" element={<VoiceSendFile />} />
            <Route path="mms/campaign" element={<MMSCampaignBuilder />} />
            <Route path="mms/quick-send" element={<MMSQuickSender />} />
            <Route path="mms/send-file" element={<MMSFileSender />} />
            <Route
              path="whatsapp/campaign"
              element={<WhatsAppCampaignBuilder />}
            />

            <Route
              path="whatsapp/quick-send"
              element={<WhatsAppQuickSender />}
            />
            <Route path="whatsapp/send-file" element={<WhatsAppFileSender />} />
            <Route path="chatbox" element={<Chatbox />} />
            <Route path="docs" element={<DevDocuments />} />
            <Route
              path="specific-quicksend"
              element={<SpecificNumberQuickSend />}
            />
            <Route path="senderid/senderidreq" element={<SenderIdreq />} />
            <Route path="/sms-template/add-new" element={<AddnewTemplate />} />
            <Route path="template/update/:id" element={<UpdateTemplate />} />
            <Route path="blacklist" element={<Blacklist />} />
            <Route path="blacklist/add-new" element={<AddNewBlacklist />} />
            <Route path="blacklist/update/:id" element={<UpdateBlacklist />} />
            <Route path="sms/send-file" element={<Sendflie />} />
            <Route path="sms/eom-records" element={<EomRecords />} />
            <Route path="sms/eom-records/:id" element={<EomRecordData />} />
            <Route path="developers" element={<Developers />} />
            <Route path="import" element={<Import />} />
            {/* Contact routes */}
            <Route path="contact" element={<ContactGroup />} />
            <Route path="contact/create" element={<NewContactGroup />} />
            <Route path="contact/:uid/create" element={<AddPhoneNumber />} />
            <Route path="contact/:uid/view" element={<ViewContact />} />
            <Route path="contact/update/:uid" element={<UpdateContact />} />
            <Route path="account/top-up" element={<TopUp />} />
            <Route path="profile" element={<Profile />} />
            <Route path="billing" element={<Billing />} />

            <Route path="transactions" element={<Transactions />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;

const Authentication = () => {
  const navigate = useNavigate();
  const token = getToken();
  useEffect(() => {
    if (token !== null) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  return <></>;
};
