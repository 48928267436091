import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { MdCommentsDisabled, MdOutlineMms } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { AiFillWechat } from "react-icons/ai";
import { Outlet } from "react-router-dom";
import Sending from "./Sending/index";
import Reports from "./Reports";
import Templates from "./Templates";
import SMS from "./SMS/index";
import Customers from "./Customers/index";
import {Image} from "antd";
import Voice from "./Voice/index";
import Mms from "./MMS/mms";
import WhatsApp from "./Whatsapp";
import { HiUserGroup} from "react-icons/hi";
import { GoGraph } from "react-icons/go";
import { BiBlock ,BiGroup} from "react-icons/bi"; 
import { GoRepoTemplate } from "react-icons/go";
import {
  RiFileForbidLine,
  RiMailForbidFill,
  RiMailForbidLine,
} from "react-icons/ri";
import { GrChatOption } from "react-icons/gr";
import { BsCodeSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveNav,
  setDropDown,
} from "../../redux/sideNavSlice/SideNavSlice";
import { ImBlocked } from "react-icons/im";
import { setViewPage } from "../../redux/viewPageSlice";
import {  LuLayoutTemplate } from "react-icons/lu";
import { Drawer } from "antd";

const SideNav = (props: any) => {
  const { activeSidebar, setActiveSidebar, dropDown ,logo} = props;
  const {
    color,
    showDropDown,
    arrow_right,
    rotate,
    arrRef,
    dropdown,
    color2,
    sendingg,
    rotate1,
    sendings,
    color3,
    opensms,
    rotate2,
    smss,
  } = props;

  const dispatch = useDispatch();

  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  const handlePageComponent = async (navString: any) => {
    // console.log(navString);
    let temp: any = {
      dashboard: true,
      reports: false,
      allMessages: false,
      inbox: false,
      sent: false,
      campaigns: false,
      contactGroups: false,
      senderId: false,
      numbers: false,
      smsTemplates: false,
      blacklist: false,
      smsCampaignBuilder: false,
      smsQuickSend: false,
      smsSendFile: false,
      voiceCampaignBuilder: false,
      voiceQuickSend: false,
      voiceSendFile: false,
      mmsCampaignBuilder: false,
      mmsQuickSend: false,
      mmsSendFile: false,
      whatsAppCampaignBuilder: false,
      whatsAppQuickSend: false,
      whatsAppSendFile: false,
      chatbox: false,
      developers: false,
    };
    for (const property in temp) {
      if (property === navString) {
        temp[property] = true;
      } else {
        temp[property] = false;
      }
    }
    await dispatch(setViewPage(temp));
  };
  const navigate = useNavigate();

  return (
    <div className="gap-[20px] flex ">
      {/* Mobile Navigation */}
      <Drawer 
        className="relative"
        title=""
        placement="left"
        
        onClose={() => {
          setActiveSidebar(false);
        }}
        open={activeSidebar}
      >
        <div className="space-y-[20px] pl-[150px] absolute top-0 left-0 ">
          {/* <NavLink to="/" className=" mb-[20px]"> */}
          {/* <img src={logo} alt="" className="cursor-pointer w-[90px]" /> */}
          {/* </NavLink> */}
          <div className=" bg-white h-screen flex-col gap-[8px] flex  fixed   md:basis-[15%] overflow-auto">
            <div className="overflow-y-scroll sidebar">

            <NavLink to="/dashboard">
        <Image src={logo} alt="logo" className="cursor-pointer pt-3 max-w-[90px] block mobile:hidden my-4 " />
      </NavLink>
              <div
                // className="flex items-center gap-[16px] nav_link rounded-[6px] pl-[18px] py-[12px] max-w-[223px] cursor-pointer group"
                className={
                  active === "dashboard"
                    ? " nav_active  flex items-center gap-[16px] py-[12px] pl-[18px] cursor-pointer  nav_link w-full rounded-[6px] group mt-10"
                    : "flex items-center gap-[16px] py-[12px] pl-[18px] nav_link w-full rounded-[6px] cursor-pointer group mt-0 mobile:mt-10"
                }
                onClick={() => {
                  navigate("/dashboard");
                  dispatch(setActiveNav("dashboard"));
                  dispatch(
                    setDropDown({
                      reportDropDown: false,
                      sendingDropDown: false,
                      smsDropDown: false,
                      voiceDropDown: false,
                      mmsDropdown: false,
                      whatsapp: false,
                    })
                  );
                }}
              >
                {/* <img src={dashboardB} alt="" className='text-white hh' /> */}
                <GoGraph />

                <p
                  className={
                    active == "dashboard"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Dashboards
                </p>
              </div>

             
              <div
                onClick={() => {
                  
                  dispatch(setActiveNav("sms"));
                }}
              >
                <SMS
                  color3={color3}
                  opensms={opensms}
                  arrow_right={arrow_right}
                  rotate2={rotate2}
                  smss={smss}
                />
              </div>
             

              {/* CONTACT GROUPS */}

             

              {/* SENDING */}
              <div
                onClick={() => {
                  dispatch(setActiveNav("sending"));
                }}
              >
                <Sending
                  sendingg={sendingg}
                  color2={color2}
                  arrow_right={arrow_right}
                  rotate1={rotate1}
                  sent={sendings}
                  dropdown={dropdown}
                />
              </div>

              {/* BLACKLIST */}

              <div
                className={
                  active === "blackList"
                    ? "flex items-center nav_active rounded-[6px]  pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                    : "flex items-center  rounded-[6px]  py-[12px] pl-[18px] nav_link w-[223px] cursor-pointer group"
                }
                onClick={() => {
                  // handleReportsClick("reportDropDown");
                  navigate("/blacklist");
                  dispatch(setActiveNav("blackList"));
                  dispatch(
                    setDropDown({
                      reportDropDown: false,
                      sendingDropDown: false,
                      smsDropDown: false,
                      voiceDropDown: false,
                      mmsDropdown: false,
                      whatsapp: false,
                    })
                  );
                }}
              >
                <div className="flex items-center  gap-[16px]">
                  {/* <img src={blacklist} alt="" className='group-hover:invert' /> */}
                  <div className="">
                    <BiBlock />
                  </div>
                  <p
                    className={
                      active === "blackList"
                        ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                        : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                    }
                  >
                    Blacklist
                  </p>
                </div>
              </div>
              
              <div
                className={
                  active === "contact"
                    ? "flex items-center nav_active   rounded-[6px] pl-[18px] py-[12px]  nav_link w-[223px] cursor-pointer group"
                    : "flex items-center  rounded-[6px]  py-[12px]  pl-[18px] nav_link w-[223px] cursor-pointer group"
                }
                onClick={() => {
                  navigate("/contact");
                  dispatch(setActiveNav("contact"));
                  // handleReportsClick('reportDropDown')
                  dispatch(
                    setDropDown({
                      reportDropDown: false,
                      sendingDropDown: false,
                      smsDropDown: false,
                      voiceDropDown: false,
                      mmsDropdown: false,
                      whatsapp: false,
                    })
                  );
                }}
              >
                <div className="flex w-full items-center gap-[16px]">
                
                <BiGroup />
                  <p
                    className={
                      active === "contact"
                        ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                        : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                    }
                  >
                    Contact Groups{" "}
                  </p>
                </div>
              </div>
              <div
                className={
                  active === "template"
                    ? "flex items-center nav_active   rounded-[6px] py-[12px] pl-[18px] nav_link w-[223px] cursor-pointer group"
                    : "flex items-center  rounded-[6px] py-[12px] pl-[18px] nav_link w-[223px] cursor-pointer group"
                }
                onClick={() => {
                  navigate("/sms-template");
                  dispatch(setActiveNav("contact"));
                  // handleReportsClick('reportDropDown')
                  dispatch(
                    setDropDown({
                      reportDropDown: false,
                      sendingDropDown: false,
                      smsDropDown: false,
                      voiceDropDown: false,
                      mmsDropdown: false,
                      whatsapp: false,
                    })
                  );
                }}
              >
                <div className="flex w-full items-center gap-[16px]">
                
                <GoRepoTemplate />
                  <p
                    className={
                      active === "contact"
                        ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                        : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                    }
                  >
                    Template
                  </p>
                </div>
              </div>
             
              <div
                onClick={() => {
                  dispatch(setActiveNav("reports"));
                }}
              >
                <Reports
                  color={color}
                  showDropDown={showDropDown}
                  arrow_right={arrow_right}
                  rotate={rotate}
                  arrRef={arrRef}
                  dropdown={dropdown}
                />
              </div>

              <div
                className={
                  active === "developer"
                    ? "flex items-center nav_active  rounded-[6px] pl-[18px]  py-[12px] nav_link w-[223px] cursor-pointer group"
                    : "flex items-center  rounded-[6px]  py-[12px] pl-[18px] nav_link w-[223px] cursor-pointer group"
                }
                onClick={() => {
                  navigate("/developers");
                  dispatch(setActiveNav("developer"));
                  dispatch(
                    setDropDown({
                      reportDropDown: false,
                      sendingDropDown: false,
                      smsDropDown: false,
                      voiceDropDown: false,
                      mmsDropdown: false,
                      whatsapp: false,
                    })
                  );
                }}
              >
                <div className="flex items-center gap-[16px]">
                  <BsCodeSquare />
                  <p
                    className={
                      active === "developer"
                        ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                        : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                    }
                  >
                    Developers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {/* sidebar */}
      <div className="bg-white lg:px-[16px] pt-[20px] h-screen flex-col gap-[8px] hidden lg:flex fixed md:basis-[15%] overflow-auto  ">
        <div className="overflow-y-scroll sidebar h-full  border-gray-500">
          <NavLink to="dashboard">
            <div
              // className="flex items-center gap-[16px] nav_link rounded-[6px] pl-[18px] py-[12px] max-w-[223px] cursor-pointer group"
              className={
                active === "dashboard"
                  ? " nav_active  flex items-center gap-[16px] py-[12px] cursor-pointer  nav_link w-full pl-[18px] rounded-[6px] group"
                  : "flex items-center gap-[16px] py-[12px]  nav_link w-full pl-[18px] rounded-[6px] cursor-pointer group"
              }
            >
              {/* <img src={dashboardB} alt="" className='text-white hh' /> */}
              <GoGraph />

              <p
                className={
                  active === "dashboard"
                    ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                    : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                }
              >
                Dashboard
              </p>
            </div>
          </NavLink>

          {/* SMS */}
          <div
            onClick={() => {
              dispatch(setActiveNav("sms"));
            }}
          >
            <SMS
              color3={color3}
              opensms={opensms}
              arrow_right={arrow_right}
              rotate2={rotate2}
              smss={smss}
              dropDown={dropDown}
              // openSms={o}
            />
          </div>

          {/* CUSTOMERS */}
          <div
            onClick={() => {
              dispatch(setActiveNav("customers"));
            }}
          >
            <Customers 
              color3={color3}
              opensms={opensms}
              arrow_right={arrow_right}
              rotate2={rotate2}
              smss={smss}
              dropDown={dropDown}
              // openSms={o}
            />
          </div>

          {/* SENDING */}
          <div
            onClick={() => {
              dispatch(setActiveNav("sending"));
            }}
          >
            <Sending
              sendingg={sendingg}
              color2={color2}
              arrow_right={arrow_right}
              rotate1={rotate1}
              sent={sendings}
              handlePageComponent={handlePageComponent}
            />
          </div>

          {/* BLACKLIST */}

          <NavLink to="/blacklist">
            <div
              className={
                active === "blackList"
                  ? "flex items-center nav_active rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                // navigate("/customer/dashboard/blacklist");
                dispatch(setActiveNav("blackList"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center  gap-[16px]">
                {/* <img src={blacklist} alt="" className='group-hover:invert' /> */}
                <div className="">
                  <BiBlock />
                </div>
                <p
                  className={
                    active === "blackList"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Blacklist
                </p>
              </div>
            </div>
          </NavLink>

          {/* Contact */}

          <NavLink to="/contact">
            <div
              className={
                active === "contact"
                  ? "flex items-center nav_active rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                // navigate("/customer/dashboard/blacklist");
                dispatch(setActiveNav("contact"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center  gap-[16px]">
                <div className="">
                <BiGroup />
                </div>
                <p
                  className={
                    active === "contact"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Contact Groups
                </p>
              </div>
            </div>
          </NavLink>
          <NavLink to="/sms-template">
            <div
              className={
                active === "contact"
                  ? "flex items-center nav_active rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                // navigate("/customer/dashboard/blacklist");
                dispatch(setActiveNav("contact"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center  gap-[16px]">
                <div className="">
                <GoRepoTemplate />
                </div>
                <p
                  className={
                    active === "contact"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  SMS Template
                </p>
              </div>
            </div>
          </NavLink>

          {/* Template */} 
          {/* <NavLink to="/template">
            <div
              className={
                active === "template"
                  ? "flex items-center nav_active nav_link rounded-[6px] pl-[18px] py-[12px]  w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                // navigate("/customer/dashboard/blacklist");
                dispatch(setActiveNav("template"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center  gap-[16px]">
                <div className="">
                  <HiTemplate/>
                </div>
                <p
                  className={
                    active === "template"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal nav_active group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Template
                </p>
              </div>
            </div>
          </NavLink> */}

          {/* templates */}
          {/* <div
            onClick={() => {
              dispatch(setActiveNav("template"));
            }}
          >
            <Templates
              color={color}
              showDropDown={showDropDown}
              arrow_right={arrow_right}
              rotate={rotate}
              arrRef={arrRef}
              dropdown={dropdown}
              handlePageComponent={handlePageComponent}
            />
          </div> */}
          {/* Reports */}
          <div
            onClick={() => {
              dispatch(setActiveNav("report"));
            }}
          >
            <Reports
              color={color}
              showDropDown={showDropDown}
              arrow_right={arrow_right}
              rotate={rotate}
              arrRef={arrRef}
              dropdown={dropdown}
              handlePageComponent={handlePageComponent}
            />
          </div>
          {/* devs */}
          <NavLink to="/developers">
            <div
              className={
                active === "developer"
                  ? "flex items-center nav_active  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                dispatch(setActiveNav("developer"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center gap-[16px]">
                <BsCodeSquare />
                <p
                  className={
                    active === "developer"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Developers
                </p>
              </div>
            </div>
          </NavLink>

         
          {/* VOICE */}

          {/* <div
            className=""
            onClick={() => {
              dispatch(setActiveNav("Voice"));
            }}
          >
            <Voice
              color3={color3}
              rotate2={rotate2}
              arrow_right={arrow_right}
              smss={smss}
              handlePageComponent={handlePageComponent}
            />
          </div> */}

          {/* MMS */}

          {/* <div
            onClick={() => {
              dispatch(setActiveNav("mms"));
            }}
          >
            <Mms
              color3={color3}
              rotate2={rotate2}
              arrow_right={arrow_right}
              smss={smss}
              handlePageComponent={handlePageComponent}
            />
          </div> */}

          {/*==========This is  WhatsApp ============= */}
          {/* <div
            onClick={() => {
              dispatch(setActiveNav("whatsapp"));
            }}
          >
            <WhatsApp
              color3={color3}
              rotate2={rotate2}
              arrow_right={arrow_right}
              smss={smss}
              handlePageComponent={handlePageComponent}
            />
          </div> */}

          {/* ============This is chatbox navlink========== */}
          {/* <NavLink to="/chatbox">
            <div
              className={
                active === "chatbox"
                  ? "flex items-center nav_active   rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
                  : "flex items-center  rounded-[6px] pl-[18px] py-[12px] nav_link w-[223px] cursor-pointer group"
              }
              onClick={() => {
                dispatch(setActiveNav("chatbox"));
                dispatch(
                  setDropDown({
                    reportDropDown: false,
                    sendingDropDown: false,
                    smsDropDown: false,
                    voiceDropDown: false,
                    mmsDropdown: false,
                    whatsapp: false,
                  })
                );
              }}
            >
              <div className="flex items-center gap-[16px]">
                <AiFillWechat />
                <p
                  className={
                    active === "chatbox"
                      ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                      : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                  }
                >
                  Chat Box
                </p>
              </div>
            </div>
          </NavLink> */}

         
        </div>
      </div>
      {/* right section */}
      <div className=" lg:ml-[280px]   container mx-auto md:basis-[85%] lg:pr-[32px] lg:max-w-[calc(100%-280px)]">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default SideNav;

{
  /* for mobile navigation */
}
