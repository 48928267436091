import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../toastAlert.js/toastalert";
import { getToken } from "../utils/tokenUtils";
import FormSkeleton from "../components/FormSkeleton";
import { apiBaseUrl } from "../globals/baseUrl";

// Define the customer interface
interface Customer {
  uid: string;
  name: string;
  email: string;
  status: string;
  created_at: string;
  subscription: string;
  balance: string;
  assign_plan?: string;
}

const Customers = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [balance, setBalance] = useState<number>(0);
  const [selectedCustomerUid, setSelectedCustomerUid] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // New state for tracking submission
  const [balanceError, setBalanceError] = useState<string>(""); // State for validation error message

  // Fetch customers from API
  const fetchCustomers = async () => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${apiBaseUrl}/users/reseller/search`,
        {
          length: 10,
          start: 0,
          orderBy: "uid",
          orderDir: "asc",
          search: searchInput,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success" && Array.isArray(response.data.data?.data)) {
        setCustomers(response.data.data.data);
      } else {
        showFailedToastAlert(`Error: ${response.data.message || "Unexpected API response"}`);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        showFailedToastAlert(`Error: ${error.message}`);
      } else {
        showFailedToastAlert("An unknown error occurred.");
      }
    } finally {
      setBalance(0);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [searchInput]);

  // Validate the balance input
  const validateBalance = (value: string) => {
    const regex = /^[0-9]+(\.[0-9]+)?$/; // Ensure it's a valid number (integer or float)
    if (!regex.test(value) || Number(value) <= 0) {
      setBalanceError("Please enter a valid positive number greater than 0.");
      return false;
    }
    setBalanceError(""); // Clear error if valid
    return true;
  };

  // Handle balance add
  const handleBalanceChange = async (actionType: "add" | "deduct") => {
    if (selectedCustomerUid && balance > 0) {
      setIsSubmitting(true); // Set submitting state to true when the form is being submitted
      try {
        const token = getToken();
        const endpoint =
          actionType === "add"
            ? `${apiBaseUrl}/users/customers/${selectedCustomerUid}/addUnit`
            : `${apiBaseUrl}/users/customers/${selectedCustomerUid}/removeUnit`;
  
        const response = await axios.post(
          endpoint,
          {
            add_unit: balance,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('balance', response)
  
        if (response.data.status === "success") {
          showSuccessfulToastAlert(
            actionType === "add" ? "Balance added successfully!" : "Balance deducted successfully!"
          );

          setIsModalVisible(false); // Close modal
        } else {
          showFailedToastAlert(`${response.data.message || "Unexpected API response"}`);
        }
      } catch (error) {
        console.log("Balance change error", error);
        showFailedToastAlert(`Failed to ${actionType} balance.`);
      } finally {
        fetchCustomers(); // Refresh customers list
        setIsSubmitting(false); // Set submitting state back to false after the operation
      }
    } else {
      showFailedToastAlert("Please enter a valid balance amount.");
    }
  };
  

  // Define table columns
  const columns = [
    {
      name: "UID",
      selector: (row: Customer) => row.uid,
    },
    {
      name: "Name",
      cell: (row: Customer) => (
        <div className="flex gap-[10px] cursor-pointer">
          <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full"></div>
          <div>
            <p className="text-[14px] text-[#5F6368]">{row.name}</p>
            <p className="text-[11px] text-[#5F6368]">{row.email}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row: Customer) => row.created_at,
    },
    {
      name: "Subscription",
      selector: (row: Customer) => row.subscription,
    },
    {
        name: "Balance",
        selector: (row: Customer) => row.balance || "N/A"
    },
    {
      name: "Action",
      cell: (row: Customer) => (
        <div className="flex gap-2 justify-start align-middle ">
          <button
            onClick={() => {
              setSelectedCustomerUid(row.uid);
              setIsModalVisible(true);
            }}
            className="bg-yellow-600 rounded py-2 px-2 text-md text-white"
          >
            Update Balance
          </button>
        </div>
      ),
    },
  ];

  const data = customers.map((customer) => ({
    uid: customer.uid,
    name: customer.name,
    email: customer.email,
    status: customer.status,
    created_at: customer.created_at,
    subscription: customer.subscription,
    balance: customer.balance,
  }));

  return (
    <div className="bg-white">
      <div className="mt-[16px] pt-[23px] pb-[12px] flex items-center justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] font-bold pl-[19.74px]">Customers</h2>
        <input
          type="text"
          placeholder="Search customers..."
          className="border p-[10px] text-[#5F6368] rounded-[3px]"
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

    
      <DataTable columns={columns} data={data} pagination className="px-[10px] h-[550px]" />
     

      {/* Add Balance Modal */}
      {isModalVisible && (
        <div className="modal-overlay fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="modal-content bg-white rounded-lg p-6 w-96">
            <h3 className="text-xl font-semibold mb-4">Add Balance</h3>
            <div>
              <div className="mb-4">
                <label htmlFor="balance" className="block text-sm font-medium text-gray-700">Balance</label>
                <input
                  type="text"
                  id="balance"
                  name="balance"
                  value={balance}
                  onChange={(e) => {
                    const newBalance = Number(e.target.value);
                    setBalance(newBalance);
                    validateBalance(e.target.value);
                  }}
                  className="border border-gray-300 rounded w-full py-2 px-3 mt-1"
                  placeholder="Enter amount"
                  required
                />
                {balanceError && <p className="text-red-500 text-sm">{balanceError}</p>}
              </div>
              <button
                type="button"
                className={`w-full py-2 ${isSubmitting ? "bg-gray-500" : "bg-blue-700"} text-white rounded mt-4`}
                onClick={() => {
                  if (validateBalance(balance.toString())) {
                    handleBalanceChange("add");
                  }
                }}
                disabled={isSubmitting} // Disable the button during submission
              >
                { "Add Balance"}
              </button>
              <button
                type="button"
                className={`w-full py-2 ${isSubmitting ? "bg-gray-500" : "bg-red-700"} text-white rounded mt-4`}
                onClick={() => {
                  if (validateBalance(balance.toString())) {
                    handleBalanceChange("deduct");
                  }
                }}
                disabled={isSubmitting} // Disable the button during submission
              >
                {"Deduct Balance"}
              </button>

            </div>
            <button
              onClick={() => setIsModalVisible(false)}
              className="mt-4 w-full text-center bg-gray-700 py-2 text-white "
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Customers;
