import axios, { AxiosResponse } from 'axios';
import { baseUrl } from '../url';
import { getToken } from "../../utils/tokenUtils";

interface KhaltiPaymentResponse {        
    status?: string;  // Expected response status
    message?: string; // Message from the server, if any
    // Add additional fields as necessary based on the API response
}

/**
 * Verifies the payment using the provided pidx.
 * 
 * @param pidx - The payment index to verify.
 * @returns The response data from the payment verification API.
 * @throws An error if the payment verification fails.
 */
export default async function verifyPayment(pidx: string): Promise<KhaltiPaymentResponse> {
    const token = getToken();
    const payload = {
        pidx: pidx
    };

    try {
        const response: AxiosResponse<KhaltiPaymentResponse> = await axios.request({
            method: 'POST',
            url: `${baseUrl}/auth/payment/verify`,
            headers: {
              "Content-Type": "Application/json", 
              Authorization: `Bearer ${token}`,
            },
            data: payload, // Use 'data' instead of 'json'
            maxBodyLength: Infinity, // Optional: adjust as needed
        });
                
        return response.data; // Returns the response data
    } catch (error: any) {
        // Enhanced error handling
        if (error.response) {
            // Server responded with a status other than 2xx
            console.error('Error verifying payment:', error.response.data);
            throw new Error(error.response.data.message || 'Failed to verify payment.');
        } else if (error.request) {
            // No response received from server
            console.error('No response received:', error.request);
            throw new Error('No response from payment server. Please try again later.');
        } else {
            // Error setting up the request
            console.error('Error setting up request:', error.message);
            throw new Error('Error verifying payment. Please try again.');
        }
    }
}
